import Vue from "vue";
import { EagleFormConfigInterface } from "@/components/form/types/form";
import { paymentTypes } from "modules/base/config/paymentConstantsForB2C";

class formConfig {
  protected vm?: Vue;
  protected formKey?: string;

  _defaultData() {
    return {};
  }

  get(vm: Vue, formKey: string): EagleFormConfigInterface {
    this.vm = vm;
    this.formKey = formKey;

    return {
      pageTitle: "module.providerPaymentSetting",
      defaultData: this._defaultData,
      dataAction: {
        status: false,
        back: false,
        delete: false,
        refresh: false,
      },
      blocks: [
        {
          // title: "form_block.base",
          disabled: (formInfo) => {
            //@ts-ignore
            return formInfo.formData.member_id != vm.memberId;
          },
          help: (formInfo) => {
            //@ts-ignore
            return formInfo.formData.member_id != vm.memberId
              ? vm.$t("only.main-manager.edit")
              : "";
          },
          data: {
            payment_type: {
              label: "data.payment_type",
              required: true,
              type: "liff-selection",
              multiple: true,
              grid: {
                md: 12,
                lg: 12,
                xl: 12,
              },
              options: (formInfo) => {
                return paymentTypes.map((type) => ({
                  text: vm.$t(`data.payment_type.${type}`),
                  value: type,
                }));
              },
              hasChanged: ({
                originalData,
                data,
                bothEmpty,
              }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false;
                return window.eagleLodash.isEqual(originalData, data) === false;
              },
            },
          },
        },
      ],
    };
  }
}

export default new formConfig();
