export const paymentTypeMap = {
    // 付款方式
    "PAYMENT_TYPE_CASH_ON_DELIVERY": 'cash_on_delivery', // 貨到付款
    "PAYMENT_TYPE_LINEPAY": 'linepay', // LinePay
    "PAYMENT_TYPE_ECPAY": 'ecpay', // ECPay
    "PAYMENT_TYPE_ATM": 'atm', // ECPay
}

// @ts-ignore
export const paymentTypes = Object.keys(paymentTypeMap).map((key:string) => paymentTypeMap[key])
